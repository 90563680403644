import { useFetcher, useLocation, useNavigate, useNavigation } from "@remix-run/react";
// import Stepper from "antd-mobile/es/components/stepper";
// import Toast from "antd-mobile/es/components/toast";
import React, { useCallback, useEffect, useRef, useState } from "react";
// import TextInput from "react-autocomplete-input";
import { createContext, useContext, useContextSelector } from "use-context-selector";
// import { closeConfirmModal, confirmModal } from "~/modules/recipe/components/confirmModal";
import { useRootData } from "~/utils/data/useRootData";
import { usePopoverSelectorContext } from "./popoverContext";

const PantryContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const usePantryContextVals = () => {
  const [open, setOpen] = React.useState(false);
  const [pantries, setPantries] = React.useState([]);
  const [pantryItems, setPantryItems] = useState([]);
  const pantryFetcher = useFetcher();
  const [formVals, setFormVals] = useState({});
  const formRef = useRef({});
  const subscriptionRef = useRef();
  const rootData = useRootData();
  const locationRef = useRef();
  const openSide = usePopoverSelectorContext((state) => state.openSide);
  const closeSide = usePopoverSelectorContext((state) => state.closeSide);
  const sidesOpen = usePopoverSelectorContext((state) => state.sidesOpen);

  formRef.current = formVals;

  const location = useLocation();
  const navigate = useNavigate();
  const navigation = useNavigation();
  locationRef.current = location;

  useEffect(() => {
    if (sidesOpen.includes("pantry")) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [sidesOpen]);

  const processPantryItemChange = (data) => {
    if (data.eventType === "INSERT") {
      setPantryItems((prevItems) => [...prevItems, data.new]);
    } else if (data.eventType === "UPDATE") {
      setPantryItems((prevItems) =>
        prevItems.map((item) => {
          if (item.id === data.new.id) {
            return data.new;
          }
          return item;
        })
      );
    } else if (data.eventType === "DELETE") {
      setPantryItems((prevItems) => prevItems.filter((item) => item.id !== data.old.id));
    }
  };

  useEffect(() => {
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
    }
    // if (pantries.length > 0) {
    //   subscriptionRef.current = supabase
    //     .channel(`pantry-items`)
    //     .on(
    //       "postgres_changes",
    //       {
    //         event: "*",
    //         schema: "public",
    //         table: "UserPantryItems",
    //         filter: `userPantryId=in.(${pantries.map((p) => p.id).join(", ")})`,
    //       },
    //       processPantryItemChange
    //     )
    //     .subscribe();
    // }
  }, [pantries]);
  useEffect(() => {
    if (pantryFetcher.data && pantryFetcher.data.pantries) {
      setPantries(pantryFetcher.data.pantries);
    }
    if (pantryFetcher.data && pantryFetcher.data.pantryItems) {
      setPantryItems(pantryFetcher.data.pantryItems);
    }
    if (pantryFetcher.data && pantryFetcher.data.success && pantryFetcher.data.message) {
      // Toast.show({
      //   content: pantryFetcher.data.message,
      //   icon: "success",
      // });
    }
  }, [pantryFetcher.data]);

  useEffect(() => {
    if (!rootData.hasSub) return;
    const form = new FormData();
    form.set("action", "getPantries");
    pantryFetcher.submit(form, {
      action: "/api/recipe/pantry",
      method: "post",
    });
  }, [rootData.hasSub]);

  const addToPantry = useCallback(
    async (ing) => {
      const ingredient = ing ?? formRef.current;
      const form = new FormData();
      form.append("action", "setPantryItem");
      form.append("ingredient", ingredient.ingredient);
      form.append("quantity", ingredient.quantity);
      form.append("unit", ingredient.unit);
      // closeConfirmModal();
      pantryFetcher.submit(form, {
        action: "/api/recipe/pantry",
        method: "post",
      });
    },
    [pantryFetcher, formVals]
  );

  const updatePantryItem = useCallback(
    async (ing) => {
      const ingredient = ing ?? formRef.current;
      const form = new FormData();
      form.append("action", "updatePantryItem");
      form.append("id", ingredient.id);
      form.append("ingredient", ingredient.ingredient);
      form.append("quantity", ingredient.quantity);
      form.append("unit", ingredient.unit);
      // closeConfirmModal();
      setPantryItems((prevItems) =>
        prevItems.map((item) => {
          if (item.id === ingredient.id) {
            return {
              ...item,
              ingredient: ingredient.ingredient,
              quantity: ingredient.quantity,
              unit: ingredient.unit,
            };
          }
          return item;
        })
      );
      pantryFetcher.submit(form, {
        action: "/api/recipe/pantry",
        method: "post",
      });
    },
    [pantryFetcher, formVals]
  );

  const changeQuantity = useCallback(
    (val) => {
      setFormVals({ ...formRef.current, quantity: val });
    },
    [formVals]
  );
  const changeUnit = useCallback(
    (val) => {
      setFormVals({ ...formRef.current, unit: val });
    },
    [formVals]
  );

  const clearForm = () => {
    setFormVals({});
    // closeConfirmModal();
  };

  //create a function to clean the state pantry items
  const cleanPantryItems = () => {
    setPantryItems([]);
  };

  const handleAddToPantry = useCallback(
    (ingredient) => {
      const found = pantryItems.find((item) => item.ingredient === ingredient.ingredient);
      let initialValues = ingredient;
      if (found) {
        setFormVals({ ...found });
        initialValues = found;
      } else {
        setFormVals({ ...ingredient });
      }
      // confirmModal({
      //   header: "Pantry Total Quantity",
      //   title: toTitleCase(ingredient.ingredient),
      //   closeOnMaskClick: false,
      //   bodyClassName: "content-p-0 flex items-center",
      //   content: (
      //     <div className="flex w-36 flex-col items-center justify-center">
      //       {/* <Stepper
      //         style={{ "--height": "36px", "--input-width": "70px" }}
      //         defaultValue={initialValues.quantity}
      //         onChange={changeQuantity}
      //         min={1}
      //         max={1000}
      //         aria-label={"Servings Adjustment"}
      //         className="large-stepper"
      //       />
      //       <div className="border-0 p-0 text-center text-gray-800">
      //         <div className="relative flex w-full rounded-md">
      //           <TextInput
      //             className={
      //               "block w-full min-w-0 flex-1 rounded-md border-0 border-gray-300 p-0 text-center hover:bg-gray-50 focus:border-accent-500 focus:bg-gray-50 focus:ring-accent-500 sm:text-sm"
      //             }
      //             options={["cup", "tablespoon", "teaspoon", "pound", "oz"]}
      //             trigger={""}
      //             Component={"input"}
      //             type="text"
      //             defaultValue={ingredient.unit}
      //             onSelect={changeUnit}
      //           // onEnter={handleUnitChange}
      //           // onBlur={handleUnitChange}
      //           />
      //         </div>
      //       </div> */}
      //     </div>
      //   ),
      //   actions: [
      //     [
      //       { key: "cancel", text: "Cancel", color: "warning", onClick: clearForm },
      //       {
      //         key: "save",
      //         text: "Save",
      //         onClick: addToPantry,
      //         primary: true,
      //         default: true,
      //         className: "bg-primary-500 hover:bg-primary-600",
      //       },
      //     ],
      //   ],
      // });
    },
    [addToPantry, pantryItems]
  );

  const handleRemoveFromPantry = useCallback(
    async (id) => {
      const form = new FormData();
      form.append("action", "deletePantryItem");
      form.append("id", id);
      pantryFetcher.submit(form, {
        action: "/api/recipe/pantry",
        method: "post",
      });
    },
    [pantryFetcher]
  );

  const handleChangeInPantry = useCallback(
    async (ingredient) => {
      const form = new FormData();
      form.append("action", "decreasePantryItem");
      form.append("ingredient", ingredient.ingredient);
      form.append("quantity", ingredient.quantity);
      form.append("unit", ingredient.unit);
      pantryFetcher.submit(form, {
        action: "/api/recipe/pantry",
        method: "post",
      });
    },
    [pantryFetcher]
  );

  const openPantry = () => {
    setOpen(true);
    openSide("pantry");
  };

  const closePantry = () => {
    setOpen(false);
    closeSide("pantry");
  };

  return {
    open,
    setOpen,
    openPantry,
    closePantry,
    pantryItems,
    handleAddToPantry,
    addToPantry,
    handleRemoveFromPantry,
    cleanPantryItems,
    updatePantryItem,
    pantryFetcher,
  };
};

const PantryContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const pantryContext = usePantryContextVals();

  return <PantryContext.Provider value={{ ...pantryContext }}>{props.children}</PantryContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const usePantryContext = () => useContext(PantryContext);
const usePantrySelectorContext = (selector: any) => {
  return useContextSelector(PantryContext, selector);
};
export { PantryContextProvider, usePantryContext, usePantrySelectorContext };
